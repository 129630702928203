<template>
  <div class="info">
    <div class="container">
      <router-link to="/" class="home-btn">Home</router-link>
      <div v-if="LANG == 'de'">
        <h1>Datenschutz&shy;erkl&auml;rung</h1>
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <p>
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten
          Datenschutzerkl&auml;rung.
        </p>
        <h3>Datenerfassung auf dieser Website</h3>
        <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
          &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.
        </p>
        <h4>Wie erfassen wir Ihre Daten?</h4>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
          Sie in ein Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
          allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten
          erfolgt automatisch, sobald Sie diese Website betreten.
        </p>
        <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen
          zur Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>
        <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
          verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r
          die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
          Aufsichtsbeh&ouml;rde zu.
        </p>
        <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
        <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen.
        </p>
        <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerkl&auml;rung.</p>
        <h2>2. Hosting</h2>
        <h3>Externes Hosting</h3>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website erfasst
          werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und
          Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die &uuml;ber eine Website generiert
          werden, handeln.
        </p>
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und bestehenden Kunden
          (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots
          durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist und
          unsere Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p>Wir setzen folgenden Hoster ein:</p>
        <p>
          easyname GmbH<br />
          Fernkorngasse 10/3/501,<br />
          A-1100 Wien
        </p>
        <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
        <h3>Datenschutz</h3>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen
          Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir
          erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
          Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>
        <p>
          Christian Mathias Wölfig<br />
          A-2105 Unterrohrbach, Wiesener Straße 20<br />
          Österreich
        </p>

        <p>
          Telefon: +43 660/5578989<br />
          E-Mail: office@woelfig.com
        </p>
        <p>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke
          und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>

        <h3>Speicherdauer</h3>
        <p>
          Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
          Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend
          machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen
          rechtlich zul&auml;ssigen Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
        </p>
        <h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3>
        <p>
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren
          Drittstaaten. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogene Daten in diese Drittstaaten &uuml;bertragen und dort
          verarbeitet werden. Wir weisen darauf hin, dass in diesen L&auml;ndern kein mit der EU vergleichbares Datenschutzniveau garantiert
          werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden
          herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht ausgeschlossen
          werden, dass US-Beh&ouml;rden (z.&nbsp;B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu &Uuml;berwachungszwecken
          verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen Einfluss.
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <p>
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung
          bleibt vom Widerruf unber&uuml;hrt.
        </p>
        <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)</h3>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
          GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
          EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN
          EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE
          BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
          F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER
          GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH
          GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R
          DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN
          DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        </p>
        <h3>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3>
        <p>
          Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
          insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
          Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
          verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie
          die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
          ist.
        </p>
        <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>
        <p>
          Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
          Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
          verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo; auf
          &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von
          Dritten mitgelesen werden.
        </p>
        <h3>Auskunft, L&ouml;schung und Berichtigung</h3>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
          gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht
          auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie
          sich jederzeit an uns wenden.
        </p>
        <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
        <p>
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie
          sich jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
        </p>
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit,
            um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder
            Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren
            Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die
            Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
          Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
          eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h3>Widerspruch gegen Werbe-E-Mails</h3>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht
          ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten
          sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails,
          vor.
        </p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Cookies</h3>
        <p>
          Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine Textdateien und richten auf Ihrem
          Endger&auml;t keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung (Session-Cookies) oder
          dauerhaft (permanente Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
          gel&ouml;scht. Permanente Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen&nbsp;oder eine
          automatische L&ouml;schung durch Ihren Webbrowser erfolgt.
        </p>
        <p>
          Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem Endger&auml;t gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens
          (z.&nbsp;B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
        </p>
        <p>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne diese
          nicht funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das
          Nutzerverhalten auszuwerten&nbsp;oder Werbung anzuzeigen.
        </p>
        <p>
          Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
          bestimmter, von Ihnen erw&uuml;nschter Funktionen (funktionale Cookies, z.&nbsp;B. f&uuml;r die Warenkorbfunktion) oder zur
          Optimierung der Website (z.&nbsp;B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
          Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschlie&szlig;lich
          auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und Cookies nur im
          Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en sowie das automatische
          L&ouml;schen der Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
          Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
        </p>
        <p>
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hier&uuml;ber im Rahmen dieser
          Datenschutzerkl&auml;rung gesondert informieren und ggf. eine Einwilligung abfragen.
        </p>
        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden
          personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese
          Daten geben wir nicht ohne Ihre Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
          eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>
        <p>
          Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
          abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
          Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
        </p>
        <h2>5. Plugins und Tools</h2>
        <h3>YouTube mit erweitertem Datenschutz</h3>
        <p>
          Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon
          House, Barrow Street, Dublin 4, Irland.
        </p>
        <p>
          Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass YouTube keine Informationen &uuml;ber
          die Besucher auf dieser Website speichert, bevor diese sich das Video ansehen. Die Weitergabe von Daten an YouTube-Partner wird
          durch den erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So stellt YouTube &ndash; unabh&auml;ngig davon, ob
          Sie sich ein Video ansehen &ndash; eine Verbindung zum Google DoubleClick-Netzwerk her.
        </p>
        <p>
          Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird
          dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
          erm&ouml;glichen Sie YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie verhindern,
          indem Sie sich aus Ihrem YouTube-Account ausloggen.
        </p>
        <p>
          Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endger&auml;t speichern oder vergleichbare
          Wiedererkennungstechnologien (z.&nbsp;B. Device-Fingerprinting) einsetzen. Auf diese Weise kann YouTube Informationen &uuml;ber
          Besucher dieser Website erhalten. Diese Informationen werden u. a. verwendet, um Videostatistiken zu erfassen, die
          Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
        </p>
        <p>
          Gegebenenfalls k&ouml;nnen nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorg&auml;nge ausgel&ouml;st werden, auf
          die wir keinen Einfluss haben.
        </p>
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
          Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Weitere Informationen &uuml;ber Datenschutz bei YouTube finden Sie in deren Datenschutzerkl&auml;rung unter:
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer"
            >https://policies.google.com/privacy?hl=de</a
          >.
        </p>
        <h3>Vimeo</h3>
        <p>
          Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West 18th Street, New York, New York 10011,
          USA.
        </p>
        <p>
          Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von Vimeo
          hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse.
          Dies gilt auch dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei Vimeo besitzen. Die von Vimeo erfassten
          Informationen werden an den Vimeo-Server in den USA &uuml;bermittelt.
        </p>
        <p>
          Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, erm&ouml;glichen Sie Vimeo, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen
          Profil zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account ausloggen.
        </p>
        <p>
          Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw. vergleichbare Wiedererkennungstechnologien (z.&nbsp;B.
          Device-Fingerprinting).
        </p>
        <p>
          Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
          Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission sowie nach Aussage von Vimeo auf
          &bdquo;berechtigte Gesch&auml;ftsinteressen&ldquo; gest&uuml;tzt. Details finden Sie hier:
          <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a>.
        </p>
        <p>
          Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerkl&auml;rung von Vimeo unter:
          <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a>.
        </p>
        <h3>Google Web Fonts</h3>
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim
          Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
          anzuzeigen.
        </p>
        <p>
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google
          Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf
          Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des
          Schriftbildes auf seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde (z.&nbsp;B. eine Einwilligung zur
          Speicherung von Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter
          <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer"
            >https://developers.google.com/fonts/faq</a
          >
          und in der Datenschutzerkl&auml;rung von Google:
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer"
            >https://policies.google.com/privacy?hl=de</a
          >.
        </p>
        <h3>Font Awesome</h3>
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten und Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6
          Porter Road Apartment 3R, Cambridge, Massachusetts, USA.
        </p>
        <p>
          Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Fonts in ihren Browsercache, um Texte, Schriftarten und Symbole
          korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Font Awesome aufnehmen.
          Hierdurch erlangt Font Awesome Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung
          von Font Awesome erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der einheitlichen
          Darstellung des Schriftbildes auf unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde (z.&nbsp;B. eine
          Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
          DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>Wenn Ihr Browser Font Awesome nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
        <p>
          Weitere Informationen zu Font Awesome finden Sie&nbsp;und in der Datenschutzerkl&auml;rung von Font Awesome unter:
          <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener noreferrer">https://fontawesome.com/privacy</a>.
        </p>
        <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
      </div>
      <div v-else>
        <h1>Data protection declaration</h1>

        <h2>1. Data protection at a glance</h2>

        <h3>General information</h3>
        <p>
          The following information provides a simple overview of what happens to your personal data when you visit this website. Personal
          data are all data with which you can be personally identified. For detailed information on the subject of data protection, please
          refer to our data protection declaration below this text.
        </p>

        <h3>Data collection on this website</h3>
        <h4>Who is responsible for data collection on this website?</h4>
        <p>
          The data processing on this website is carried out by the website operator. You can find its contact details in the section &
          ldquo; Note on the responsible body & ldquo; in this data protection declaration.
        </p>
        <h4>How do we collect your data?</h4>
        <p>
          On the one hand, your data is collected when you communicate it to us. This can be e.g. be data that you enter in a contact form.
        </p>
        <p>
          Other data are recorded automatically or with your consent when you visit the website by our IT systems. This is mainly technical
          data (e.g. Internet browser, operating system or time of the page was viewed). This data is collected automatically as soon as you
          enter this website.
        </p>
        <h4>What do we use your data for?</h4>
        <p>
          Some of the data is collected in order to ensure that the website is error-free ; listen. Other data can be used to analyze your
          user behavior.
        </p>
        <h4>What rights do you have with regard to your data?</h4>
        <p>
          You have the right at any time to free information about the origin, recipient ; nger and purpose of your stored personal data.
          You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you
          can revoke this consent at any time for the future. You also have the right, under certain circumstances, to request that the
          processing of your personal data be restricted. You also have the right to lodge a complaint with the responsible supervisory
          authority.
        </p>
        <p>You can contact us at any time for this or for further questions on the subject of data protection.</p>

        <h3>Analysis tools and tools from third-party providers</h3>
        <p>
          When you visit this website, your surfing behavior can be statistically evaluated. This is mainly done with so-called analysis
          programs.
        </p>
        <p>You can find detailed information on these analysis programs in the following data protection declaration.</p>

        <h2>2. Hosting</h2>

        <h3>External hosting</h3>
        <p>
          This website is hosted by an external service provider (host). The personal data recorded on this website is stored on the host's
          servers. This can primarily involve IP addresses, contact requests, meta and communication data, contract data, contact details,
          names, website accesses and other data generated via a website.
        </p>
        <p>
          The use of the hoster takes place for the purpose of contract fulfillment with our potential and existing customers (Art. 6 Para.
          1 lit.b GDPR) and in the interest of a secure, fast and efficient provision of our online offer by a professional provider (Art. 6
          Para . 1 lit. f GDPR).
        </p>
        <p>
          Our host will only process your data insofar as this is necessary to fulfill its performance obligations and follow our
          instructions with regard to this data.
        </p>
        <p>We use the following hoster:</p>

        <p>
          easyname GmbH<br />
          Fernkorngasse 10/3/501,<br />
          A-1100 Wien
        </p>

        <h2>3. General notes and mandatory information</h2>

        <h3>Data protection</h3>
        <p>
          The operators of this website take the protection of your personal data very seriously. We treat your personal data confidentially
          and in accordance with the statutory data protection regulations and this data protection declaration.
        </p>
        <p>
          When you use this website, various personal data are collected. Personal data are data with which you can be personally
          identified. This data protection declaration explains what data we collect and what we use it for. It also explains how and for
          what purpose this happens.
        </p>
        <p>
          We point out that data transmission over the Internet (e.g. when communicating by e-mail) is subject to security measures. may
          have. Complete protection of data from access by third parties is not possible.
        </p>

        <h3>Note on the responsible body</h3>
        <p>The responsible body for data processing on this website is:</p>
        <p>
          Christian Mathias Wölfig<br />
          A-2105 Unterrohrbach, Wiesener Straße 20<br />
          Österreich
        </p>

        <p>
          Telefon: +43 660/5578989<br />
          E-Mail: office@woelfig.com
        </p>

        <p>
          The responsible body is the natural or legal person who alone or jointly with others about the purposes and means of processing
          personal data (e.g. names, email addresses, etc.) .) decides.
        </p>

        <h3>Storage period</h3>
        <p>
          Unless a specific storage period is specified in this data protection declaration, your personal data will remain with us until
          the purpose for data processing no longer applies. If you make a legitimate deletion request or revoke your consent to data
          processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e .g. tax
          or commercial retention periods); in the latter case, the deletion takes place after these reasons no longer apply.
        </p>

        <h3>Note on data transfer to the USA and other third countries</h3>
        <p>
          Among other things, we use tools from companies based in the USA or other third countries that are not secure in terms of data
          protection law. If these tools are active, your personal data can be transferred to these third countries and processed there. We
          point out that in these countries no data protection level comparable to that in the EU can be guaranteed. For example, US
          companies are obliged to surrender personal data to security authorities without you as the person concerned being able to take
          legal action against this. It cannot therefore be ruled out that US authorities (e.g. secret services) process, evaluate and
          permanently store your data on US servers for monitoring purposes. We have no influence on these processing activities.
        </p>
        <h3>Revocation of your consent to data processing</h3>
        <p>
          Many data processing operations are only possible with your express consent. You can revoke your consent at any time. The legality
          of the data processing carried out until the revocation remains unaffected by the revocation.
        </p>

        <h3>Right to object to the collection of data in special cases as well as against direct advertising (Art. 21 GDPR)</h3>
        <p>
          IF THE DATA PROCESSING ON THE BASIS OF ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE AT ANY TIME THE RIGHT TO OBJECT TO THE PROCESSING
          OF YOUR PERSONAL DATA FOR REASONS ARISING FROM YOUR SPECIAL SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS.
          THE RELEVANT LEGAL BASIS ON WHICH PROCESSING IS REQUIRED, CAN BE REFERRED TO IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO
          LONGER PROCESS YOUR AFFECTED PERSONAL DATA UNLESS WE MAY MANDOR MANDATORY PROTECTIVE SIGNS AND REQUIRE THE PROCESSING, OR CAREFUL
          FOR THE PROCESSING FOR THE ASSISTANCE, EXERCISE OR DEFENSE OF LEGAL CLAIMS (OBJECTION ACCORDING TO ART. 21 PARA. 1 GDPR).
        </p>
        <p>
          YOUR PERSONAL DATA WILL BE PROCESSED IN ORDER TO COMPLETE YOUR ADVERTISING DIRECTLY OBJECT TO THE PROCESSING OF PERSONAL DATA
          CONCERNING YOU FOR THE PURPOSE OF SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT IN CONNECTION WITH SUCH DIRECT
          ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL NO LONGER BE USED FOR THE PURPOSE OF DIRECT ADVERTISING (OBJECTION ACCORDING
          TO ART. 21 (2) GDPR).
        </p>

        <h3>Right to lodge a complaint with the responsible supervisory authority</h3>
        <p>
          In the event of a violation of the GDPR, the data subject has the right to lodge a complaint with a supervisory authority, in
          particular in the Member State of yours habitual residence, place of work or the location of the suspected infringement. The right
          of appeal exists without prejudice to other administrative or judicial remedies.
        </p>

        <h3>Right to data transferability</h3>
        <p>
          You have the right to transfer data that we process automatically on the basis of your consent or in fulfillment of a contract to
          yourself or to a third party to hand over the standard, machine-readable format. If you request the direct transfer of the data to
          another person responsible, this will only be done if it is technically feasible.
        </p>

        <h3>SSL or TLS encryption</h3>
        <p>
          This site uses for security reasons and to protect the transmission of confidential content, such as orders or inquiries that you
          send to us as the site operator , an SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address
          line of the browser changes from & ldquo; http: // & ldquo; on & ldquo; https: // & ldquo; changes and the lock symbol in your
          browser line.
        </p>
        <p>If the SSL or TLS encryption is activated, the data that you transmit to us cannot be read by third parties be.</p>

        <h3>Information, deletion and correction</h3>
        <p>
          You have the right to free information about your stored personal data, their origin and recipient and the purpose of the data
          processing at any time within the framework of the applicable legal provisions and, if applicable, a right to correct or delete
          this data. You can contact us at any time if you have any further questions on the subject of personal data.
        </p>

        <h3>Right to restriction of processing</h3>
        <p>
          You have the right to request that the processing of your personal data be restricted. You can contact us at any time for this
          purpose. The right to restrict processing exists in the following cases:
        </p>
        <ul>
          <li>
            If you dispute the accuracy of your personal data stored by us, we usually need time to do so ; check. For the duration of the
            test, you have the right to request that the processing of your personal data be restricted.
          </li>
          <li>
            If the processing of your personal data was / is unlawful, you can Instead of deleting it, you can request the restriction of
            data processing.
          </li>
          <li>
            If we no longer need your personal data, but you need them to exercise, defend or assert legal claims. You have the right to
            request the restriction of the processing of your personal data instead of the deletion.
          </li>
          <li>
            If you have lodged an objection according to Art. between your and our interests. As long as it is not yet clear whose interests
            prevail, you have the right to request the restriction of the processing of your personal data.
          </li>
        </ul>
        <p>
          If you have restricted the processing of your personal data , may this data - apart from their storage & ndash; processed only
          with your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another
          natural or legal person or for reasons of an important public interest of the European Union or a member state will be.
        </p>

        <h3>Objection to advertising e-mails</h3>
        <p>
          We hereby object to the use of contact data published within the framework of the imprint obligation for sending unsolicited
          advertising and information materials. The operators of the pages expressly reserve the right to take legal action in the event of
          the unsolicited sending of advertising information, such as spam e-mails.
        </p>

        <h2>4. Data collection on this website</h2>

        <h3>Cookies</h3>
        <p>
          Our website uses so-called "cookies". Cookies are small text files and do not cause any damage to your device. They are stored
          either temporarily for the duration of a session (session cookies) or permanently (permanent cookies) on your device. Session
          cookies are automatically deleted after your visit. Permanent cookies remain stored on your end device until you delete them
          yourself or your web browser automatically deletes them.
        </p>
        <p>
          In some cases, cookies from third-party companies can also be stored on your end device when you enter our site (third-party
          cookies). These enable us or you to use certain third-party services (e.g. cookies for processing payment services).
        </p>
        <p>
          Cookies have various functions. Numerous cookies are technically necessary because certain website functions would not work
          without them (e.g. the shopping cart function or the display of videos). Other cookies are used to evaluate user behavior or to
          display advertising.
        </p>
        <p>
          Cookies that are used to carry out the electronic communication process (necessary cookies) or to provide certain functions you
          want (functional cookies, e.g. . & nbsp; B. for the shopping cart function) or to optimize the website (e.g. & nbsp; e.g. cookies
          to measure the web audience) are stored on the basis of Art. 6 Para. 1 lit. other legal basis is specified. The website operator
          has a legitimate interest in the storage of cookies for the technically error-free and optimized provision of its services. If
          consent to the storage of cookies has been requested, the cookies concerned are stored exclusively on the basis of this consent
          (Art. 6 Para. 1 lit. a GDPR); the consent can be revoked at any time.
        </p>
        <p>
          You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases. Exclude
          or generally exclude and activate the automatic deletion of cookies when you close the browser. If cookies are deactivated, the
          functionality of this website may be limited.
        </p>
        <p>
          If cookies are used by third-party companies or for analysis purposes, we will inform you separately within the scope of this data
          protection declaration and, if necessary . request consent.
        </p>

        <h3>Inquiry by e-mail, telephone or fax</h3>
        <p>
          If you contact us by e-mail, telephone or fax, your request including all personal data (name, request) will be processed for the
          purpose of processing Your request is saved and processed by us. We do not pass on this data without your consent.
        </p>
        <p>
          The processing of this data takes place on the basis of Art. 6 Para. 1 lit. b GDPR, provided that your request is related to the
          performance of a contract or is necessary to carry out pre-contractual measures. In all other cases, the processing is based on
          our legitimate interest in the effective processing of the inquiries addressed to us (Art. 6 Para. 1 lit. f GDPR) or on your
          consent (Art. 6 Para. 1 lit. a GDPR) if this has been requested.
        </p>
        <p>
          The data you send to us via contact requests will remain with us until you ask us to delete it, revoke your consent to storage or
          the purpose for which No data storage is required (e.g. after your request has been processed). Mandatory legal provisions &
          ndash; in particular statutory retention periods & ndash; remain untouched.
        </p>

        <h2>5. Plugins and Tools</h2>

        <h3>YouTube with extended data protection</h3>
        <p>
          This website includes videos from YouTube. The operator of the pages is Google Ireland Limited (& ldquo; Google & ldquo;), Gordon
          House, Barrow Street, Dublin 4, Ireland.
        </p>
        <p>
          We use YouTube in the extended data protection mode. According to YouTube, this mode has the effect that YouTube does not store
          any information about visitors to this website before they watch the video. However, the transfer of data to YouTube partners is
          not necessarily excluded by the extended data protection mode. So, YouTube & ndash; Regardless of whether you are watching a video
          & ndash; a connection to the Google DoubleClick network.
        </p>
        <p>
          As soon as you start a YouTube video on this website, a connection to the YouTube servers is established. The YouTube server is
          informed which of our pages you have visited. If you are logged into your YouTube account, you enable YouTube to assign your
          surfing behavior directly to your personal profile. You can prevent this by logging out of your YouTube account.
        </p>
        <p>
          Furthermore, YouTube can save various cookies on your device after starting a video or use comparable recognition technologies
          (e.g. e. G. Device fingerprinting). In this way, YouTube can receive information about visitors to this website. This information
          is used, among other things, to record video statistics, improve user-friendliness and prevent attempted fraud.
        </p>
        <p>
          If necessary, further data processing operations may be triggered after the start of a YouTube video, which we cannot trigger Have
          influence.
        </p>
        <p>
          The use of YouTube is in the interest of an appealing presentation of our online offers. This represents a legitimate interest
          within the meaning of Art. 6 Paragraph 1 lit. f GDPR. If a corresponding consent has been requested, processing takes place
          exclusively on the basis of Art. 6 Paragraph 1 lit. a GDPR; the consent can be revoked at any time.
        </p>
        <p>
          You can find more information about data protection at YouTube in their data protection declaration at:
          <a href="https://policies.google.com/privacy?hl=de " target=" _ blank " rel=" noopener noreferrer ">
            https://policies.google.com/privacy?hl=de </a
          >.
        </p>

        <h3>Vimeo</h3>
        <p>
          This website uses plugins from the Vimeo video portal. The provider is Vimeo Inc., 555 West 18th Street, New York, New York 10011,
          USA.
        </p>
        <p>
          If you visit one of our pages with a Vimeo video, a connection to the Vimeo servers is established . The Vimeo server is informed
          which of our pages you have visited. Vimeo also obtains your IP address. This also applies if you are not logged in to Vimeo or do
          not have a Vimeo account. The information recorded by Vimeo is transmitted to the Vimeo server in the USA.
        </p>
        <p>
          If you are logged into your Vimeo account, you enable Vimeo to assign your surfing behavior directly to your personal profile .
          You can prevent this by logging out of your Vimeo account.
        </p>
        <p>Vimeo uses cookies or comparable recognition technologies (e.g. device fingerprinting) to recognize website visitors.</p>
        <p>
          Vimeo is used in the interest of an appealing presentation of our online offers. This represents a legitimate interest within the
          meaning of Art. 6 Paragraph 1 lit. f GDPR. If a corresponding consent has been requested, processing takes place exclusively on
          the basis of Art. 6 Paragraph 1 lit. a GDPR; the consent can be revoked at any time.
        </p>
        <p>
          The data transfer to the USA is based on the standard contractual clauses of the EU Commission and, according to Vimeo, on &
          ldquo; legitimate business interests & ldquo; supported. Details can be found here:
          <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer"> https://vimeo.com/privacy </a>.
        </p>
        <p>
          Further information on handling user data can be found in Vimeo's data protection declaration at:
          <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer"> https: / /vimeo.com/privacy </a>.
        </p>

        <h3>Google Web Fonts</h3>
        <p>
          This page uses so-called web fonts, which are provided by Google, for the uniform display of fonts. When you call up a page, your
          browser loads the required web fonts into your browser cache in order to display texts and fonts correctly.
        </p>
        <p>
          For this purpose, the browser you are using must connect to the Google servers. This gives Google knowledge that this website has
          been accessed via your IP address. The use of Google WebFonts is based on Art. 6 Para. 1 lit. f GDPR. The website operator has a
          legitimate interest in the uniform representation of the typeface on his website. If a corresponding consent has been requested
          (e.g. consent to the storage of cookies), processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a GDPR; the
          consent can be revoked at any time.
        </p>
        <p>If your browser does not support web fonts, a standard font will be used by your computer.</p>
        <p>
          Further information on Google Web Fonts can be found at
          <a href="https://developers.google.com/fonts/faq" target="_ blank" rel="noopener noreferrer">
            https://developers.google.com/fonts/faq
          </a>
          and in the privacy policy tion from Google:
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
            https://policies.google.com/privacy?hl = de </a
          >.
        </p>

        <h3>Font Awesome</h3>
        <p>
          This page uses Font Awesome for the uniform display of fonts and symbols. The provider is Fonticons, Inc., 6 Porter Road Apartment
          3R, Cambridge, Massachusetts, USA.
        </p>
        <p>
          When you call up a page, your browser loads the required fonts into your browser cache in order to store texts, fonts and Display
          symbols correctly. For this purpose, the browser you are using must connect to the Font Awesome servers. This gives Font Awesome
          knowledge that this website has been accessed via your IP address. Font Awesome is used on the basis of Art. 6 Para. 1 lit. f
          GDPR. We have a legitimate interest in the uniform presentation of the typeface on our website. If a corresponding consent has
          been requested (e.g. consent to the storage of cookies), processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a
          GDPR; the consent can be revoked at any time.
        </p>
        <p>If your browser does not support Font Awesome, a standard font will be used by your computer.</p>
        <p>
          Further information about Font Awesome can be found & nbsp; and in the data protection declaration ; tion of Font Awesome at:
          <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener noreferrer"> https://fontawesome.com/privacy </a>.
        </p>

        <p>Source: <a href="https://www.e-recht24.de"> https://www.e-recht24.de </a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    LANG() {
      return this.$store.getters.getSelectedLang;
    },
  },
};
</script>

<style lang="scss">
.info {
  text-align: left;
}
.home-btn {
  background: #222;
  padding: 1rem 3rem;
  color: #fff;
  display: block;
  width: max-content;
  margin: 2rem 0;
  border-radius: 1rem;
}
</style>
