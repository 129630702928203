<template>
  <div class="info">
    <div class="container">
      <router-link to="/" class="home-btn">Home</router-link>
      <div v-if="LANG == 'de'">
        <h1>Impressum</h1>
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Christian Mathias Wölfig<br />
          A-2105 Unterrohrbach, Wiesener Straße 20<br />
          Österreich
        </p>
        <h2>Kontakt</h2>
        <p>
          Telefon: +43 660/5578989 <br />
          E-Mail: office@woelfig.com
        </p>
        <!-- <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE999999999
        </p> -->
        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
          <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a
          >.<br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <span>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></span>
      </div>
      <div v-else>
        <h1>Imprint</h1>
        <h2>ACCORDING TO § 5 TMG</h2>
        <p>
          Christian Mathias Wölfig <br />
          A-2105 Unterrohrbach, Wiesener Straße 20 <br />
          Austria
        </p>
        <h2>Contact</h2>
        <p>
          Phone: +43 660/5578989 <br />
          Email: office@woelfig.com
        </p>
        <!-- <h2>Sales tax ID</h2> -->
        <!-- <p>
          Sales tax identification number according to § 27 a sales tax law:<br />
          DE999999999
        </p> -->
        <h2>EU dispute settlement</h2>
        <p>
          The European Commission provides a platform for online dispute resolution (OS):
          <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer"> https://ec.europa.eu/consumers/odr/ </a>
          .
          <br />
          You can find our email address in the legal notice above.
        </p>
        <h2>CONSUMER DISPUTE RESOLUTION / UNIVERSAL ARBITRATION BODY</h2>
        <p>We are neither willing nor obliged to participate in dispute settlement proceedings before a consumer arbitration board.</p>
        <span>Source: <a href="https://www.e-recht24.de"> eRecht24 </a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    LANG() {
      return this.$store.getters.getSelectedLang;
    },
  },
};
</script>

<style lang="scss">
.info {
  text-align: left;
}
.home-btn {
  background: #222;
  padding: 1rem 3rem;
  color: #fff;
  display: block;
  width: max-content;
  margin: 2rem 0;
  border-radius: 1rem;
}
</style>
